import { FullHeightLayout } from '../../layouts/full-height-layout';

export const PrivacyPolicy: React.FC = () => {
  return (
    <FullHeightLayout>
      <div className='privacy-policy__container'>
        <p className='c35'>
          <span className='c1 c2'>AMAZEYOU</span>
        </p>
        <p className='c35'>
          <span className='c28 c12 c1 c36'>IMPORTANT PRIVACY INFORMATION</span>
        </p>
        <p className='c9'>
          <span className='c11 c1'>
            In order to use some of our apps or websites, we may ask you to
            enter your name, email, hair color, ethnicity type, style level,
            areas for improvement, and fashion preferences, and ask other
            onboarding questions. In some of our apps you are able to skip some
            of the onboarding questions by tapping on Skip or similar
            indication. We also automatically collect from your device: language
            settings, IP address, time zone, type and model of a device, device
            settings, operating system, Internet service provider, mobile
            carrier, hardware ID, Facebook ID, and some other unique identifiers
            (such as IDFA and AAID). We need this data to provide our services,
            analyze how our customers use the app, to serve ads.
          </span>
        </p>
        <p className='c9'>
          <span className='c11 c1'>
            For improving the app and attracting users, we use third party
            solutions. As a result, we may process data using solutions
            developed by Amplitude, Facebook, Firebase, Google, Apple,
            Appsflyer, Crashlytics. Therefore, some of the data is stored and
            processed on servers of such third parties. This enables us to (1)
            analyze different interactions (how often users make subscriptions,
            the average weight and height of our users, how many users chose a
            particular area for improvement); (2) serve ads (and are able to
            show them only to a particular group of users, for example, to
            subscribers). Consequently, we, in particular, better understand in
            what of our features and content you see the most value and are able
            to focus on them to enhance your experience and increase the quality
            of our products.
          </span>
        </p>
        <p className='c9'>
          <span className='c28 c12 c1'>
            Please read our Privacy Policy below to know more about what we do
            with data (Section 2), what data privacy rights are available to you
            (Section 5) and who will be the data controller (Section 12). If any
            questions will remain unanswered or you would like to exercise your
            privacy rights, please contact us at{' '}
          </span>
          <span className='c13 c12 c1 c28'>support@amazeyou.co</span>
        </p>
        <p className='c35'>
          <span className='c36 c28 c12 c1'>PRIVACY POLICY</span>
        </p>
        <p className='c9'>
          <span className='c28 c12 c1'>
            This Privacy Policy explains what personal data is collected when
            you use the our mobile applications, websites and the services
            provided through them (together{' '}
          </span>
          <span className='c10 c12 c1'>&ldquo;App&rdquo;</span>
          <span className='c28 c12 c1'>&nbsp;or </span>
          <span className='c10 c1 c12'>&ldquo;Service&rdquo;</span>
          <span className='c11 c1'>
            ), how such personal data will be processed.
          </span>
        </p>
        <p className='c9'>
          <span className='c11 c1'>
            BY USING THE SERVICE, YOU PROMISE US THAT (I) YOU HAVE READ,
            UNDERSTAND AND AGREE TO THIS PRIVACY POLICY, AND (II) YOU ARE OVER
            16 YEARS OF AGE (OR HAVE HAD YOUR PARENT OR GUARDIAN READ AND AGREE
            TO THIS PRIVACY POLICY FOR YOU). If you do not agree, or are unable
            to make this promise, you must not use the Service. In such case,
            you must (a) delete your account and contact us and request deletion
            of your data; (b) cancel any subscriptions using the functionality
            provided by Apple (if you are using iOS) or Google (if you are using
            Android), any other app stores that may be available from time to
            time, or by us if you purchased it directly from our websites; and
            (c) delete the App from your devices.
          </span>
        </p>
        <p className='c9'>
          <span className='c11 c1'>
            Any translation from English version is provided for your
            convenience only. In the event of any difference in meaning or
            interpretation between the English language version of this Privacy
            Policy and any translation, the English language version will
            prevail. The original English text shall be the sole legally binding
            version.
          </span>
        </p>
        <p className='c9'>
          <span className='c28 c12 c1'>&ldquo;</span>
          <span className='c10 c12 c1'>GDPR</span>
          <span className='c11 c1'>
            &rdquo; means the General Data Protection Regulation (EU) 2016/679
            of the European Parliament and of the Council of 27 April 2016 on
            the protection of natural persons with regard to the processing of
            personal data and on the free movement of such data.
          </span>
        </p>
        <p className='c9'>
          <span className='c28 c12 c1'>&ldquo;</span>
          <span className='c10 c12 c1'>EEA</span>
          <span className='c11 c1'>
            &rdquo; includes all current member states to the European Union and
            the European Economic Area.
          </span>
        </p>
        <p className='c9'>
          <span className='c28 c12 c1'>&ldquo;</span>
          <span className='c10 c12 c1'>Process</span>
          <span className='c11 c1'>
            &rdquo;, in respect of personal data, includes to collect, store,
            and disclose to others.
          </span>
        </p>
        <p className='c34'>
          <span className='c2 c1'>TABLE OF CONTENTS</span>
        </p>
        <ul className='c5 lst-kix_2l4eqcfu6zdl-0 start'>
          <li className='c15 li-bullet-0'>
            <span className='c1 c11'>
              CATEGORIES OF PERSONAL DATA WE COLLECT
            </span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>
              FOR WHAT PURPOSES WE PROCESS PERSONAL DATA
            </span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>
              UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA (Applies only
              to EEA-based users)
            </span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>
              WITH WHOM WE SHARE YOUR PERSONAL DATA
            </span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>
              HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS
            </span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>AGE LIMITATION</span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>INTERNATIONAL DATA TRANSFERS</span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>CHANGES TO THIS PRIVACY POLICY</span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>CALIFORNIA PRIVACY RIGHTS</span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>DATA RETENTION</span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>
              HOW &ldquo;DO NOT TRACK&rdquo; REQUESTS ARE HANDLED
            </span>
          </li>
          <li className='c15 li-bullet-0'>
            <span className='c11 c1'>PERSONAL DATA CONTROLLER</span>
          </li>
          <li className='c31 li-bullet-0'>
            <span className='c11 c1'>CONTACT US</span>
          </li>
        </ul>
        <ul className='c5 lst-kix_kt7g7cd10fs2-0 start'>
          <li className='c6 li-bullet-0'>
            <span className='c14 c10 c1'>
              CATEGORIES OF PERSONAL DATA WE COLLECT
            </span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            We collect data you give us voluntarily (for example, when you
            choose your areas for improvement or send us an email). We also may
            receive data about you from third parties (for example, when you
            sign in via Apple). Finally, we collect data automatically (for
            example, your IP address).
          </span>
        </p>
        <ul className='c5 lst-kix_i8ihyur4jx2b-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>Data you give us</span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            You provide us information about yourself when you register for
            and/or use the Service. For example: name, age, gender, data on
            physical characteristics (including body type, clothing size, hair
            color, ethnicity and areas for improvement), fashion preferences,
            email address.
          </span>
        </p>
        <ul className='c5 lst-kix_hnjtrkzw8wb-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>Data provided by third parties</span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            When you use sign in with Apple to register an account in the App,
            we get personal data from your Apple ID account. This data may
            include, in particular, your name and verified email address. You
            may choose to share your real email address or an anonymous one that
            uses the private email relay service. Apple will show you their
            detailed privacy information on the sign in with Apple screen. Find
            more about sign with Apple here.
          </span>
        </p>
        <ul className='c5 lst-kix_xmsfa0om94eg-0 start'>
          <li className='c16 li-bullet-0'>
            <span className='c10 c1 c14'>Data we collect automatically:</span>
          </li>
        </ul>
        <ul className='c5 lst-kix_f8zgekhenwd-0 start'>
          <li className='c23 li-bullet-0'>
            <span className='c14 c10 c1'>Data about how you found us</span>
          </li>
        </ul>
        <p className='c20'>
          <span className='c0'>
            We collect data about your referring app or URL (that is, the app or
            place on the Web where you were when you tapped on our ad).
          </span>
        </p>
        <ul className='c5 lst-kix_bt9h4rk8u2a3-0 start'>
          <li className='c9 c25 li-bullet-0'>
            <span className='c14 c10 c1'>Device and Location data.</span>
          </li>
        </ul>
        <p className='c20'>
          <span className='c0'>
            We collect data from your mobile device. Examples of such data
            include: language settings, IP address, time zone, type and model of
            a device, device settings, operating system, Internet service
            provider, mobile carrier, hardware ID, and Facebook ID.
          </span>
        </p>
        <ul className='c5 lst-kix_fpjfd7ry3dkl-0 start'>
          <li className='c9 c25 li-bullet-0'>
            <span className='c14 c10 c1'>Usage data</span>
          </li>
        </ul>
        <p className='c20'>
          <span className='c0'>
            We record how you interact with our Service. For example, we log
            your taps on certain areas of the interface, the features, and
            content you interact with, outfits you save, how often you use the
            App, how long you are in the app and your subscription orders. We
            also record the ads in our App with which you interact (and the
            Internet links to which those adds lead).
          </span>
        </p>
        <ul className='c5 lst-kix_4f0uxlos1mbd-0 start'>
          <li className='c9 c25 li-bullet-0'>
            <span className='c14 c10 c1'>Advertising IDs</span>
          </li>
        </ul>
        <p className='c20'>
          <span className='c0'>
            We collect your Apple Identifier for Advertising
            (&ldquo;IDFA&rdquo;) or Google Advertising ID (&ldquo;AAID&rdquo;)
            (depending on the operating system of your device). You can
            typically reset these numbers through the settings of your
            device&rsquo;s operating system (but we do not control this).
          </span>
        </p>
        <ul className='c5 lst-kix_5hq27ou6le6p-0 start'>
          <li className='c9 c25 li-bullet-0'>
            <span className='c14 c10 c1'>Transaction data</span>
          </li>
        </ul>
        <p className='c20'>
          <span className='c0'>
            When you make payments through the Service, you need to provide
            financial account data, such as your credit card number, to our
            third-party service providers. We do not collect or store full
            credit card number data, though we may receive credit card-related
            data, data about the transaction, including: date, time and amount
            of the transaction, the type of payment method used.
          </span>
        </p>
        <ul className='c5 lst-kix_jw3yhbb50spn-0 start'>
          <li className='c9 c25 li-bullet-0'>
            <span className='c14 c10 c1'>Cookies</span>
          </li>
        </ul>
        <p className='c20'>
          <span className='c0'>
            A cookie is a small text file that is stored on a user&#39;s
            computer for record-keeping purposes. Cookies can be either session
            cookies or persistent cookies. A session cookie expires when you
            close your browser and is used to make it easier for you to navigate
            our Service. A persistent cookie remains on your hard drive for an
            extended period of time. We also use tracking pixels that set
            cookies to assist with delivering online advertising.
          </span>
        </p>
        <p className='c9 c33'>
          <span className='c0'>
            Cookies are used, in particular, to automatically recognize you the
            next time you visit our website. As a result, the information, which
            you have earlier entered in certain fields on the website may
            automatically appear the next time when you use our Service. Cookie
            data will be stored on your device and most of the times only for a
            limited time period.
          </span>
        </p>
        <ul className='c5 lst-kix_n0vdj8uvx6ra-0 start'>
          <li className='c6 li-bullet-0'>
            <span className='c14 c10 c1'>
              FOR WHAT PURPOSES WE PROCESS YOUR PERSONAL DATA
            </span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>We process your personal data:</span>
        </p>
        <ul className='c5 lst-kix_xe3yk5yi1rp-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>To provide our Service</span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            This includes enabling you to use the Service in a seamless manner
            and preventing or addressing Service errors or technical issues.
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            To host personal data and enable our App to operate and be
            distributed we use{' '}
          </span>
          <span className='c10 c1'>Amazon Web Services</span>
          <span className='c0'>
            , which is a hosting and backend service provided by Amazon.
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            To monitor infrastructure and the App&rsquo;s performance, we use{' '}
          </span>
          <span className='c10 c1'>Crashlytics</span>
          <span className='c1'>
            , which is a monitoring service provided by Google. Here&rsquo;s its{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://docs.fabric.io/apple/fabric/data-privacy.html%23crashlytics&amp;sa=D&amp;source=editors&amp;ust=1729010169582726&amp;usg=AOvVaw17nVxKIEsm9pyHgoaD8X0c'
            >
              Data Collection Policy
            </a>
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>We use </span>
          <span className='c10 c1'>Firebase Performance Monitoring</span>
          <span className='c1'>&nbsp;and </span>
          <span className='c10 c1'>Firebase Crash Reporting</span>
          <span className='c1'>
            , which are monitoring services provided by Google. To learn more,
            please visit{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1729010169583204&amp;usg=AOvVaw3WJmoFJ5kbvZJXIhUENVQc'
            >
              Google&rsquo;s Privacy policy
            </a>
          </span>
          <span className='c1'>&nbsp;and </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://firebase.google.com/support/privacy/&amp;sa=D&amp;source=editors&amp;ust=1729010169583388&amp;usg=AOvVaw0nt4uVoxOhZunKBXUSYfj7'
            >
              Privacy and Security in Firebase
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <ul className='c5 lst-kix_35ankurl08lk-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>To customize your experience</span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We process your personal data, in particular, such as your
            characteristics and preferences, to adjust the content of the
            Service and provide content tailored to your personal preferences.
            As a result of such processing, you get access, for example, to
            outfits containing clothing elements only from certain brands or a
            customized selection of outfits (in the in-App chat) tailored for
            achieving your fashion goals.
          </span>
        </p>
        <ul className='c5 lst-kix_2shdyyoklu0-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>
              To manage your account and provide you with customer support
            </span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We process your personal data to respond to your requests for
            technical support, Service information or to any other communication
            you initiate. This includes accessing your account to address
            technical support requests. For this purpose, we may send you, for
            example, notifications or emails about the performance of our
            Service, security, payment transactions, notices regarding our Terms
            and Conditions of Use or this Privacy Policy.
          </span>
        </p>
        <ul className='c5 lst-kix_z5p70zo98n49-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>
              To communicate with you regarding your use of our Service
            </span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We communicate with you, for example, by push notifications. These
            may include reminders and motivational messages encouraging you to
            look out for new outfits or new collections, or other information
            about the App. As a result, you may, for example, receive a push
            notification every day at a particular time reminding you to check
            for new arrivals. To opt out of receiving push notifications, you
            need to change the settings on your device.
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            The services that we use for these purposes may collect data
            concerning the date and time when the message was viewed by our
            App&rsquo;s users, as well as when they interacted with it, such as
            by clicking on links included in the message.
          </span>
        </p>
        <p className='c3'>
          <span className='c10 c1'>Intercom</span>
          <span className='c1'>
            &nbsp;provides us with message and customer service tools, which
            enable us to communicate with you within the App. When you chat with
            us via in-App chat, some of your information is automatically
            transferred to Intercom. The transfer is required to enable us to
            identify (if you shared any name related data with us) and to
            communicate with you in the in-App chat. Thus, Intercom uses these
            data to provide and fulfill its services (as set forth in their
            terms of service). Intercom is{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.privacyshield.gov/participant?id%3Da2zt0000000TNQvAAO%26status%3DActive&amp;sa=D&amp;source=editors&amp;ust=1729010169584418&amp;usg=AOvVaw1NU6m8NNWVrvYqoDxPov9Q'
            >
              EU-US Privacy Shield certified
            </a>
          </span>
          <span className='c1'>
            . Read more about Intercom&rsquo;s approach to privacy in its{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.intercom.com/terms-and-policies%23privacy&amp;sa=D&amp;source=editors&amp;ust=1729010169584625&amp;usg=AOvVaw0QMYdaSBIYzV1OtCOU1UoL'
            >
              Privacy Policy
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c1'>To communicate with you we also use </span>
          <span className='c10 c1'>Firebase Cloud Messaging</span>
          <span className='c1'>&nbsp;and </span>
          <span className='c1 c10'>Firebase Notifications</span>
          <span className='c1'>
            , which are message sending services provided by Google. Firebase
            Cloud Messaging allows us to send messages and notifications to
            users of our App across platforms such as Android and iOS. We
            integrate{' '}
          </span>
          <span className='c10 c1'>Firebase Notifications</span>
          <span className='c1'>&nbsp;with </span>
          <span className='c10 c1'>Firebase Analytics</span>
          <span className='c1'>
            &nbsp;to create analytics-based audiences and track opening and
            conversion events. As a result, we can, for example, send
            encouraging messages to users who have recently finished a training
            program. Google&#39;s{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1729010169585084&amp;usg=AOvVaw0iR7aaTRZLvmq9rrz-dbJv'
            >
              privacy policy
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c1'>We use </span>
          <span className='c10 c1'>Apple Push Notification</span>
          <span className='c1'>
            &nbsp;service (&ldquo;APNs&rdquo;), that is a notifications service
            provided by Apple. APNs allows us to send information to iOS
            devices. Apple&#39;s{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.apple.com/privacy/&amp;sa=D&amp;source=editors&amp;ust=1729010169585372&amp;usg=AOvVaw0R7b8iZI-53xkXTQp36L45'
            >
              privacy policy
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c0'>Zendesk</span>
        </p>
        <ul className='c5 lst-kix_imd23vgsmtqn-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>
              To research and analyze your use of the Service
            </span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            This helps us to better understand our business, analyze our
            operations, maintain, improve, innovate, plan, design, and develop
            the Service and our new products. We also use data for statistical
            analysis purposes, to test and improve our offers. This enables us
            to better understand what features and outfits of the Services our
            users like more, what categories of users use our Services. As a
            consequence, we often decide how to improve the Service based on the
            results obtained from this processing. For example, if we discover
            that users more often save outfits designated for evening occasions,
            we may develop and introduce more evening based outfits for this
            area into the App.
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            To perform research and analysis about how users interact with our
            App we use{' '}
          </span>
          <span className='c10 c1'>Appsflyer</span>
          <span className='c1'>
            . Appsflyer enables us to understand, in particular, how users find
            us (for example, who was the advertiser that delivered an ad to
            users, which led you to an app store with our App). Appsflyer also
            provides us with different analytics tools that enable us to
            research and analyze your use of the Service.{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.appsflyer.com/privacy-policy/&amp;sa=D&amp;source=editors&amp;ust=1729010169585975&amp;usg=AOvVaw3wGRq5HVpp5FBoss4XrhhX'
            >
              Privacy Policy
            </a>
          </span>
          <span className='c1'>. Appsflyer allows you to </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.appsflyer.com/optout&amp;sa=D&amp;source=editors&amp;ust=1729010169586178&amp;usg=AOvVaw1wFQJKoAbwgm7DXyQZtDBS'
            >
              Opt Out
            </a>
          </span>
          <span className='c0'>
            &nbsp;of having data from my device sent to AppsFlyer&#39;s servers
            for apps usage collection.
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>We use </span>
          <span className='c10 c1'>Facebook Analytics</span>
          <span className='c1'>
            , which is a service is provided by Facebook that allows us to use
            different analytical tools. On Facebook Analytics we get, in
            particular, aggregated demographics and insights on how many people
            launch our app, how often users make purchases, and other
            interactions.{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.facebook.com/about/privacy/&amp;sa=D&amp;source=editors&amp;ust=1729010169586505&amp;usg=AOvVaw0K4eTphdPSvli74FScz3Ak'
            >
              Privacy Policy
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c1'>We also use </span>
          <span className='c10 c1'>Amplitude</span>
          <span className='c1'>
            &nbsp;that is an analytics service that we use to understand how
            customers use our Service. Amplitude collects various technical
            information, in particular, time zone, type of device (phone or
            tablet), unique identifiers (such as IDFA). Amplitude also allows us
            to track various interactions that occur in our App. As a result,
            Amplitude helps us to decide what features should we focus on (for
            example, if we see that most of the users focus on one brand over
            another, we may develop more these). Amplitude is{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.privacyshield.gov/participant?id%3Da2zt000000001XZAAY%26status%3DActive&amp;sa=D&amp;source=editors&amp;ust=1729010169586918&amp;usg=AOvVaw0qG6FyHrx6PYh00Ixlv1gD'
            >
              EU-US Privacy Shield certified
            </a>
          </span>
          <span className='c1'>
            . Amplitude provides more information on how they process data in
            its{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://amplitude.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1729010169587113&amp;usg=AOvVaw1OvTR2NOhgl3NjTq4ltcbr'
            >
              Privacy Policy
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c1'>
            To analyze how visitors use the Service and to measure the
            effectiveness of some ads we use{' '}
          </span>
          <span className='c10 c1'>Google Analytics</span>
          <span className='c1'>
            , a web analysis program of Google. On Google Analytics we get, in
            particular, information on the data you enter on our website and
            users&rsquo; interactions within the website. Google allows you to
            influence the collection and processing of the information generated
            by Google, in particular, by installing a browser plug-in, available{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout/&amp;sa=D&amp;source=editors&amp;ust=1729010169587461&amp;usg=AOvVaw3Sxmg-4GSY5S6iTeskMqDO'
            >
              here
            </a>
          </span>
          <span className='c1'>
            . You can read more about how Google uses the information{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://policies.google.com/technologies/partner-sites?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1729010169587654&amp;usg=AOvVaw1lAfezGFzxmhTH62QFQbut'
            >
              here
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c1'>
            To track and analyze behavior of our App&rsquo;s users (in
            particular, how they react to changes of the App structure, text or
            any other component), we use{' '}
          </span>
          <span className='c10 c1'>Firebase Remote Config</span>
          <span className='c1'>
            . Firebase Remote Config is an A/B testing and configuration service
            provided by Google, which also enables us to tailor the content that
            our App&rsquo;s users see (for example, it allows us to show
            different onboarding screens to different users).{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://firebase.google.com/terms/data-processing-terms/&amp;sa=D&amp;source=editors&amp;ust=1729010169588034&amp;usg=AOvVaw0uaQwsUU5tcLGcreLA64uV'
            >
              Privacy Policy
            </a>
          </span>
          <span className='c1'>&nbsp;and </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://firebase.google.com/support/privacy/&amp;sa=D&amp;source=editors&amp;ust=1729010169588208&amp;usg=AOvVaw112wzUKb1TaPn8j0vPubUu'
            >
              Privacy and Security in Firebase
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c1'>We also use </span>
          <span className='c10 c1'>Firebase Analytics</span>
          <span className='c1'>
            , which is an analytics service provided by Google. In order to
            understand Google&#39;s use of data, consult Google&#39;s{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://policies.google.com/technologies/partner-sites&amp;sa=D&amp;source=editors&amp;ust=1729010169588498&amp;usg=AOvVaw3KpXYt89R250y8Bpj0MZvZ'
            >
              partner policy
            </a>
          </span>
          <span className='c1'>. </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://firebase.google.com/support/privacy/&amp;sa=D&amp;source=editors&amp;ust=1729010169588677&amp;usg=AOvVaw1NwlJ_ABmjY7Nduf7rz1ml'
            >
              Firebase Privacy information
            </a>
          </span>
          <span className='c1'>. </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1729010169588836&amp;usg=AOvVaw1LT8CVyVW2kp4w2jEGka_H'
            >
              Google&rsquo;s Privacy Policy
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c1'>
            To perform standard product analysis, we also use{' '}
          </span>
          <span className='c10 c1'>Fabric Answers</span>
          <span className='c1'>
            , which is an analytics service provided by Crashlytics, a business
            division of Google.{' '}
          </span>
          <span className='c1 c7'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://fabric.io/dpst.pdf&amp;sa=D&amp;source=editors&amp;ust=1729010169589109&amp;usg=AOvVaw1O-VZVeVAaUHzA5c7Xs5IZ'
            >
              Data Processing and Security Terms
            </a>
          </span>
          <span className='c1'>. </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://docs.fabric.io/android/fabric/data-privacy.html&amp;sa=D&amp;source=editors&amp;ust=1729010169589281&amp;usg=AOvVaw3NaJvwsYdSAW-hyqKwIelh'
            >
              Privacy information
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <ul className='c5 lst-kix_qr84xtdh3jpi-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>
              To send you marketing communications
            </span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We process your personal data for our marketing campaigns. We may
            add your email address to our marketing list, provided we receive
            consent or otherwise establish legal basis for sending you marketing
            communications. As a result, you will receive information about our
            products, such as for example, special offers. If you do not want to
            receive marketing emails from us, you can unsubscribe following
            instructions in the footer of the marketing emails.{' '}
          </span>
        </p>
        <p className='c8'>
          <span className='c0'>
            We may also show you advertisements in our App, and send you push
            notifications for marketing purposes. To opt out of receiving push
            notifications, you need to change the settings on your device.
          </span>
        </p>
        <ul className='c5 lst-kix_55ddq247n7r8-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>To personalize our ads</span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We and our partners, use your personal data to tailor ads and
            possibly even show them to you at the relevant time. For example, if
            you have installed our App, you might see ads of our products, for
            example, in your Facebook&rsquo;s feed.{' '}
          </span>
        </p>
        <p className='c3'>
          <span className='c14 c10 c1'>
            How to opt out or influence personalized advertising
          </span>
        </p>
        <p className='c3'>
          <span className='c10 c1'>iOS:</span>
          <span className='c0'>
            &nbsp;On your iPhone or iPad, go to &ldquo;Settings,&rdquo; then
            &ldquo;Privacy&rdquo; and tap &ldquo;Advertising&rdquo; to select
            &ldquo;Limit Ad Track&rdquo;. In addition, you can reset your
            advertising identifier (this also may help you to see less of
            personalized ads) in the same section.
          </span>
        </p>
        <p className='c3'>
          <span className='c10 c1'>Android:</span>
          <span className='c0'>
            &nbsp;To opt-out of ads on an Android device, simply open the Google
            Settings app on your mobile phone, tap &ldquo;Ads&rdquo; and enable
            &ldquo;Opt out of interest-based ads&rdquo;. In addition, you can
            reset your advertising identifier in the same section (this also may
            help you to see less of personalized ads).
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            To learn even more about how to affect advertising choices on
            various devices, please look at the information available{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=http://www.networkadvertising.org/mobile-choice&amp;sa=D&amp;source=editors&amp;ust=1729010169590560&amp;usg=AOvVaw0DswF_b6s40a6NIgfIs7Wt'
            >
              here
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c0'>
            In addition, you may get useful information and opt out of some
            interest-based advertising, by visiting the following links:
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            &bull; Network Advertising Initiative &ndash;{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=http://optout.networkadvertising.org/&amp;sa=D&amp;source=editors&amp;ust=1729010169590981&amp;usg=AOvVaw2nSGKNPk7n1_aBl5pOGs0a'
            >
              http://optout.networkadvertising.org/
            </a>
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            &bull; Digital Advertising Alliance &ndash;{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=http://optout.aboutads.info/&amp;sa=D&amp;source=editors&amp;ust=1729010169591244&amp;usg=AOvVaw04iftVvuBmazFlM2a2al0y'
            >
              http://optout.aboutads.info/
            </a>
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            &bull; Digital Advertising Alliance (Canada) &ndash;{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=http://youradchoices.ca/choices&amp;sa=D&amp;source=editors&amp;ust=1729010169591485&amp;usg=AOvVaw3z4MQh9UUB71fUdAWKlHz9'
            >
              http://youradchoices.ca/choices
            </a>
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            &bull; Digital Advertising Alliance (EU) &ndash;{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=http://www.youronlinechoices.com/&amp;sa=D&amp;source=editors&amp;ust=1729010169591761&amp;usg=AOvVaw1alq0OgQpNGmS0zQ877CTr'
            >
              http://www.youronlinechoices.com/
            </a>
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>&bull; DAA AppChoices page &ndash; </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=http://www.aboutads.info/appchoices&amp;sa=D&amp;source=editors&amp;ust=1729010169592096&amp;usg=AOvVaw3u7QTO5-OlpBQD_ssyfe9w'
            >
              http://www.aboutads.info/appchoices
            </a>
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            We value your right to influence the ads that you see, thus we are
            letting you know what service providers we use for this purpose and
            how some of them allow you to control your ad preferences.
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>We use </span>
          <span className='c10 c1'>Facebook Ads Manager</span>
          <span className='c1'>&nbsp;together with </span>
          <span className='c10 c1'>Facebook Custom Audience</span>
          <span className='c1'>
            , which allows us to choose audiences that will see our ads on
            Facebook or other Facebook&rsquo;s products (for example,
            Instagram). Through Facebook Custom Audience we may create a list of
            users with certain sets of data, such as an IDFA, choose users that
            have completed certain actions in the App (for example, installed
            it). As a result, we may ask Facebook to show some ads to a
            particular list of users. As a result, more of our ads may show up
            while you are using Facebook or other Facebook&rsquo;s products (for
            example, Instagram). You may learn how to opt out of advertising
            provided to you through Facebook Custom Audience{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.facebook.com/business/help/1415256572060999&amp;sa=D&amp;source=editors&amp;ust=1729010169592898&amp;usg=AOvVaw362yvyavamZIHiJ3gmTCzk'
            >
              here
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Facebook also allows its users to influence the types of ads they
            see on Facebook. To find how to control the ads you see on Facebook,
            please go here or adjust your ads settings on{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://www.facebook.com/ads/preferences/?entry_product%3Dad_settings_screen&amp;sa=D&amp;source=editors&amp;ust=1729010169593285&amp;usg=AOvVaw20rakalIH0gR-xC9EKaIvD'
            >
              Facebook
            </a>
          </span>
          <span className='c0'>.</span>
        </p>
        <p className='c3'>
          <span className='c10 c1'>Google Ads</span>
          <span className='c1'>
            &nbsp;is an ad delivery service provided by Google that can deliver
            ads to users. In particular, Google allows us to tailor the ads in a
            way that they will appear, for example, only to users that have
            conducted certain actions with our App (for example, show our ads to
            users who have purchased a subscription). Some other examples of
            events that may be used for tailoring ads include, in particular,
            installing our App, finishing a workout program. Google allows its
            users to{' '}
          </span>
          <span className='c7 c1'>
            <a
              className='c22'
              href='https://www.google.com/url?q=https://adssettings.google.com/authenticated&amp;sa=D&amp;source=editors&amp;ust=1729010169593776&amp;usg=AOvVaw2qlQxBaKK0Hgjf_smsndmV'
            >
              opt out of Google&rsquo;s personalized ads
            </a>
          </span>
          <span className='c0'>
            &nbsp;and to prevent their data from being used by Google Analytics.
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>We also use </span>
          <span className='c10 c1'>Snapchat Advertising Platform</span>
          <span className='c1'>&nbsp;together with </span>
          <span className='c10 c1'>Snapchat Audience Based Match</span>
          <span className='c0'>
            , which is an ad delivery service provided by Snapchat that can link
            the activity of some users of our App with the Snapchat advertising
            network and show some of our ads to them. As a result, you may see
            more of ads on Snapchat in case you use our App. Snapchat allows you
            to Opt Out of their audience based ads. Privacy Policy.
          </span>
        </p>
        <ul className='c5 lst-kix_gu8m0sy76v9q-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>To process your payments</span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We provide paid products and/or services within the Service. For
            this purpose, we use third-party services for payment processing
            (for example, payment processors). As a result of this processing,
            you will be able to make a payment for our Service and we will be
            notified that the payment has been made and will provide you with .
            We will not store or collect your payment card details ourselves.
            This information will be provided directly to our third-party
            payment processors.
          </span>
        </p>
        <ul className='c5 lst-kix_eg5o37pkja4-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>
              To enforce our Terms and Conditions of Use and to prevent and
              combat fraud
            </span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We use personal data to enforce our agreements and contractual
            commitments, to detect, prevent, and combat fraud. As a result of
            such processing, we may share your information with others,
            including law enforcement agencies (in particular, if a dispute
            arises in connection with our Terms and Conditions of Use).
          </span>
        </p>
        <ul className='c5 lst-kix_ja2dttt14hf7-0 start'>
          <li className='c9 c19 li-bullet-0'>
            <span className='c14 c10 c1'>To comply with legal obligations</span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We may process, use, or share your data when the law requires it, in
            particular, if a law enforcement agency requests your data by
            available legal means.
          </span>
        </p>
        <ul className='c5 lst-kix_by0ab7pic12n-0 start'>
          <li className='c6 li-bullet-0'>
            <span className='c14 c10 c1'>
              UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA (Applies only
              to EEA-based users)
            </span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            In this section, we are letting you know what legal basis we use for
            each particular purpose of processing. For more information on a
            particular purpose, please refer to Section 2. This section applies
            only to EEA-based users.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c0'>
            We process your personal data, in particular, under the following
            legal bases:
          </span>
        </p>
        <ul className='c5 lst-kix_lqn8sjpgkb7n-0 start'>
          <li className='c4 li-bullet-0'>
            <span className='c0'>your consent;</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'>- To send you marketing communications</span>
        </p>
        <ul className='c5 lst-kix_eb1g741hn1z-0 start'>
          <li className='c4 li-bullet-0'>
            <span className='c0'>to perform our contract with you;</span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'>Under this legal basis we:</span>
        </p>
        <p className='c3'>
          <span className='c0'>
            - Provide our Service (in accordance with our Terms and Conditions
            of Use)
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>- Customize your experience</span>
        </p>
        <p className='c3'>
          <span className='c0'>
            - Manage your account and provide you with customer support
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            - Communicate with you regarding your use of our Service
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>- Process your payments</span>
        </p>
        <ul className='c5 lst-kix_d1u5q7sccgk0-0 start'>
          <li className='c4 li-bullet-0'>
            <span className='c0'>
              for our (or others&#39;) legitimate interests, unless those
              interests are overridden by your interests or fundamental rights
              and freedoms that require protection of personal data;
            </span>
          </li>
        </ul>
        <p className='c3'>
          <span className='c0'>We rely on legitimate interests:</span>
        </p>
        <p className='c3'>
          <span className='c0'>
            &bull; to communicate with you regarding your use of our Service
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            This includes, for example, sending you push notifications reminding
            you of new outfits at appropriate times. The legitimate interest we
            rely on for this purpose is our interest to encourage you to use our
            Service more often. We also take into account the potential benefits
            to you of getting inspired by our outfits, which may lead you to a
            better lifestyle.
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            &bull; to research and analyze your use of the Service
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            Our legitimate interest for this purpose is our interest in
            improving our Service so that we understand users&rsquo; preferences
            and are able to provide you with a better experience (for example,
            to make the use of the App easier and more enjoyable, or to
            introduce and test new features).
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            &bull; to send you marketing communications
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            The legitimate interest we rely on for this processing is our
            interest to promote our Service in a measured and appropriate way.
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>&bull; to personalize our ads</span>
        </p>
        <p className='c3'>
          <span className='c0'>
            The legitimate interest we rely on for this processing is our
            interest to promote our Service in a reasonably targeted way.
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            &bull; to enforce our Terms and Conditions of Use and to prevent and
            combat fraud
          </span>
        </p>
        <p className='c3'>
          <span className='c0'>
            Our legitimate interests for this purpose are enforcing our legal
            rights, preventing and addressing fraud and unauthorised use of the
            Service, non-compliance with our Terms and Conditions of Use.
          </span>
        </p>
        <ul className='c5 lst-kix_vozbrc7mgojl-0 start'>
          <li className='c4 li-bullet-0'>
            <span className='c0'>to comply with legal obligations.</span>
          </li>
        </ul>
        <ul className='c5 lst-kix_srgowz3jkczj-0 start'>
          <li className='c6 li-bullet-0'>
            <span className='c14 c10 c1'>
              WITH WHOM WE SHARE YOUR PERSONAL DATA
            </span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            We share information with third parties that help us operate,
            provide, improve, integrate, customize, support, and market our
            Service. We may share some sets of personal data, in particular, for
            purposes indicated in Section 2 of this Privacy Policy. The types of
            third parties we share information with include, in particular:
          </span>
        </p>
        <ul className='c5 lst-kix_lmttgzayyydu-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>Service providers</span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We share personal data with third parties that we hire to provide
            services or perform business functions on our behalf, based on our
            instructions. We share your personal information with the following
            types of service providers:
          </span>
        </p>
        <p className='c8'>
          <span className='c0'>- cloud storage providers (Amazon)</span>
        </p>
        <p className='c8'>
          <span className='c0'>
            - data analytics providers (Facebook, Google, Appsflyer, Firebase,
            Crashlytics, Amplitude, Fabric)
          </span>
        </p>
        <p className='c8'>
          <span className='c0'>- measurement partners</span>
        </p>
        <p className='c8'>
          <span className='c0'>
            - marketing partners (in particular, social media networks,
            marketing agencies, email delivery services, Facebook, Google,
            Snapchat)
          </span>
        </p>
        <p className='c8'>
          <span className='c0'>- payment processing providers</span>
        </p>
        <p className='c8'>
          <span className='c0'>
            - communication services providers (Intercom, Zendesk)
          </span>
        </p>
        <ul className='c5 lst-kix_p1exieq72wpx-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>
              Law enforcement agencies and other public authorities
            </span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            We may use and disclose personal data to enforce our Terms and
            Conditions of Use, to protect our rights, privacy, safety, or
            property, and/or that of our affiliates, you or others, and to
            respond to requests from courts, law enforcement agencies,
            regulatory agencies, and other public and government authorities, or
            in other cases provided for by law.
          </span>
        </p>
        <ul className='c5 lst-kix_om2bnrjid4gx-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>
              Third parties as part of a merger or acquisition
            </span>
          </li>
        </ul>
        <p className='c8'>
          <span className='c0'>
            As we develop our business, we may buy or sell assets or business
            offerings. Customers&rsquo; information is generally one of the
            transferred business assets in these types of transactions. We may
            also share such information with any affiliated entity (e.g. parent
            company or subsidiary) and may transfer such information in the
            course of a corporate transaction, such as the sale of our business,
            a divestiture, merger, consolidation, or asset sale, or in the
            unlikely event of bankruptcy.
          </span>
        </p>
        <ul className='c5 lst-kix_13kx6sxe3x97-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>
              HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS
            </span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            To be in control of your personal data, you have the following
            rights:
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c10 c1'>
            Accessing / reviewing / updating / correcting your personal data
          </span>
          <span className='c0'>
            . You have the right to review, edit, or change the personal data
            that you had previously provided to us in the profile section of the
            App. If you would like to receive a copy of data we process, please
            send us a data access request.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c10 c1'>Deleting your personal data</span>
          <span className='c0'>
            . You can request erasure of your personal data, as permitted by
            law. When you request deletion of your personal data, we will use
            reasonable efforts to honor your request. In some cases we may be
            legally required to keep some of the data for a certain time; in
            such event, we will fulfill your request after we have complied with
            our obligations.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c10 c1'>
            Objecting to or restricting the use of your personal data
          </span>
          <span className='c1'>
            . You can ask us to stop using all or some of your personal data or
            limit our use thereof.
          </span>
          <span className='c10 c1'>
            Additional information for EEA-based users
          </span>
          <span className='c0'>
            . If you are based in the EEA, you have the following rights in
            addition to the above:
          </span>
        </p>
        <p className='c9 c27'>
          <span className='c10 c1'>
            The right to lodge a complaint with supervisory authority
          </span>
          <span className='c0'>
            . We would love you to contact us directly, so we could address your
            concerns. Nevertheless, you have the right to lodge a complaint with
            a competent data protection supervisory authority, in particular in
            the EU Member State where you reside, work or where the alleged
            infringement has taken place.
          </span>
        </p>
        <p className='c9 c27'>
          <span className='c10 c1'>The right to data portability</span>
          <span className='c0'>
            . If you wish to receive your personal data in a machine-readable
            format, you can do so by requesting a copy of your personal data as
            described above. The data will be made available to you in the .json
            file or other file format.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c1'>
            To exercise any of the available to you privacy rights, please send
            a request to{' '}
          </span>
          <span className='c13 c1'>support@amazeyou.co</span>
        </p>
        <ul className='c5 lst-kix_bg9c4bkozs5z-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>AGE LIMITATION</span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c1'>
            We do not knowingly process personal data from persons under 16
            years of age. If you learn that anyone younger than 16 has provided
            us with personal data, please contact us at{' '}
          </span>
          <span className='c13 c1'>support@amazeyou.co</span>
        </p>
        <ul className='c5 lst-kix_40zftwqzm12-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>INTERNATIONAL DATA TRANSFERS</span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            We may transfer personal data to countries other than the country in
            which the data was originally collected in order to provide the
            Service set forth in the Terms and Conditions of Use and for
            purposes indicated in this Privacy Policy. If these countries do not
            have the same data protection laws as the country in which you
            initially provided the information, we deploy special safeguards.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c0'>
            In particular, if we transfer personal data originating from the EEA
            to countries with not adequate level of data protection, we use one
            of the following legal bases: (i) Standard Contractual Clauses
            approved by the European Commission (details available here), or
            (ii) the European Commission adequacy decisions about certain
            countries (details available here).
          </span>
        </p>
        <ul className='c5 lst-kix_12okwujggesu-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>CHANGES TO THIS PRIVACY POLICY</span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            We may modify this Privacy Policy from time to time. If we decide to
            make material changes to this Privacy Policy, you will be notified
            through our Service or by other available means and will have an
            opportunity to review the revised Privacy Policy. By continuing to
            access or use the Service after those changes become effective, you
            agree to be bound by the revised Privacy Policy.
          </span>
        </p>
        <ul className='c5 lst-kix_pg17nmpqolia-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>CALIFORNIA PRIVACY RIGHTS</span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            This section provides additional details about how we process
            personal data of California consumers and the rights available to
            them under the California Consumer Privacy Act (&ldquo;CCPA&rdquo;)
            and California&rsquo;s Shine the Light law. Therefore, this section
            applies only to residents of California, United States.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c0'>
            For more details about the personal information we have collected,
            including the categories of sources, please see Section 1 above. We
            collect this information for purposes described in Section 2 of this
            Privacy Policy. We may also share your information with certain
            categories of third parties as indicated in Section 4.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c0'>
            Subject to certain limitations, the CCPA provides California
            consumers the right to request to know more details about the
            categories or specific pieces of personal information we collect
            (including how we use and disclose this information), to delete
            their personal information, to opt-out of any &ldquo;sales&rdquo;
            that may be occurring, and to not be discriminated against for
            exercising these rights.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c1'>
            California consumers may make a request pursuant to their rights
            under the CCPA by contacting us at{' '}
          </span>
          <span className='c13 c1'>support@amazeyou.co</span>
          <span className='c0'>
            . We will verify your request and inform you accordingly. You may
            also designate an authorized agent to exercise these rights on your
            behalf.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c14 c10 c1'>
            Access rights under California&rsquo;s Shine the Light
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c0'>
            California also provides its residents with additional access
            rights. Under Shine the Light law, the residents may ask companies
            once a year what personal information they share with third parties
            for those third parties&#39; direct marketing purposes. Learn more
            about what is considered to be personal information under the
            statute.
          </span>
        </p>
        <p className='c9 c18'>
          <span className='c1'>
            To obtain this information from us, please send an email message to{' '}
          </span>
          <span className='c1 c13'>support@amazeyou.co</span>
          <span className='c0'>
            &nbsp;which includes &ldquo;Request for California Shine the Light
            Privacy Information&rdquo; on the subject line and your state of
            residence and email address in the body of your message. Please be
            aware that not all information sharing is covered by the
            &ldquo;Shine the Light&rdquo; requirements and only information on
            covered sharing will be included in our response.
          </span>
        </p>
        <ul className='c5 lst-kix_vddrrmxj843u-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>DATA RETENTION</span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            We will store your personal data for as long as it is reasonably
            necessary for achieving the purposes set forth in this Privacy
            Policy (including providing the Service to you), which includes (but
            is not limited to) the period during which you have an account with
            the App. We will also retain and use your personal data as necessary
            to comply with our legal obligations, resolve disputes, and enforce
            our agreements.
          </span>
        </p>
        <ul className='c5 lst-kix_bccgose4p605-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>
              HOW &ldquo;DO NOT TRACK&rdquo; REQUESTS ARE HANDLED
            </span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            Except as otherwise stipulated in this Privacy Policy, this App does
            not support &ldquo;Do Not Track&rdquo; requests. To determine
            whether any of the third-party services it uses honor the &ldquo;Do
            Not Track&rdquo; requests, please read their privacy policies.
          </span>
        </p>
        <ul className='c5 lst-kix_5tn86bsnxsyr-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>PERSONAL DATA CONTROLLER</span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c0'>
            BetterMe Inc, a company registered in the state of Delaware (the
            USA) (with a registered office at 3500 South Dupont Highway, Dover,
            DE, 19801, the USA will be the controller of your personal data.
          </span>
        </p>
        <ul className='c5 lst-kix_idv68tj7h8kh-0 start'>
          <li className='c24 li-bullet-0'>
            <span className='c14 c10 c1'>CONTACT US</span>
          </li>
        </ul>
        <p className='c17'>
          <span className='c1'>
            You may contact us at any time for details regarding this Privacy
            Policy and its previous versions. For any questions concerning your
            account or your personal data please contact us at{' '}
          </span>
          <span className='c13 c1'>support@amazeyou.co</span>
        </p>
        <p className='c9'>
          <span className='c0'>Effective as of: 1 October 2024</span>
        </p>
        <p className='c21'>
          <span className='c0'></span>
        </p>
        <p className='c21'>
          <span className='c14 c28 c29'></span>
        </p>
        <p className='c21'>
          <span className='c14 c28 c29'></span>
        </p>
      </div>
    </FullHeightLayout>
  );
};
