import { Collapse } from 'antd';
import './index.css';

export const FAQ: React.FC = () => {
  return (
    <div className='faq__container'>
      <div className='faq__title title'>Frequently Asked Questions</div>
      <div className='faq__items'>
        <Collapse
          defaultActiveKey={1}
          expandIconPosition='end'
          items={[
            {
              key: '1',
              label: 'How does AmazeYou work?',
              children:
                'Our experts analyze your preferences and create curated style selections that can be purchased from your favorite fashion brands in your proximity',
            },
          ]}
        />
        <Collapse
          defaultActiveKey={2}
          expandIconPosition='end'
          items={[
            {
              key: '2',
              label: ' Which brands are included in the selections?',
              children:
                'We work with popular brands like Zara, H&M, and other accessible labels, so you can quickly assemble your wardrobe.',
            },
          ]}
        />
      </div>
    </div>
  );
};
