import './index.css';
import phoneScreen from '../../../../../../assets/images/phone-screen.jpg';

export const PhoneScreen: React.FC = () => {
  return (
    <div className='phone-screen__container'>
      <img
        className='phone-screen__image'
        src={phoneScreen}
        alt='phone screen'
      ></img>
      <div className='phone-screen__title title'>
        Confident in curated outfits that fit your style!
      </div>
    </div>
  );
};
