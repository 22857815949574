export const getDates = () => {
  // Получаем сегодняшнюю дату
  const today = new Date();

  // Получаем дату через 4 недели (28 дней)
  const futureDate = new Date();
  futureDate.setDate(today.getDate() + 28);

  // Форматируем дату в формате Mon dd (например, Nov 12)
  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  // Возвращаем результат
  return {
    today: formatDate(today),
    future: formatDate(futureDate),
  };
};
