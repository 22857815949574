import React from 'react';
import MobileApp from './components/MobileApp/MobileApp';
import { ConfigProvider } from 'antd';
import ScrollToTop from './utils/ScrollToTop';

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#ff8c80',
          colorText: '#171717',
          fontFamily: 'Montserrat',
        },
        components: {
          Collapse: {
            contentBg: '#F9F9F9',
            headerBg: '#F9F9F9',
          },
        },
      }}
    >
      <ScrollToTop />
      <MobileApp />
    </ConfigProvider>
  );
};

export default App;
