import './index.css';

export interface ButtonProps {
  label: string;
  disabled?: boolean;
  callback: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  callback,
  disabled,
}) => {
  return (
    <button className='button' onClick={callback} disabled={disabled}>
      {label}
    </button>
  );
};
