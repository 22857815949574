import { reviews, Review as ReviewI } from '../../../../types';
import { Review } from '../../../review';
import './index.css';

export const Reviews: React.FC = () => {
  return (
    <div className='reviews__container'>
      <div className='reviews__title title'>Users love our guides</div>
      <div className='reviews__info'>
        <div className='reviews__rating'>
          <span className='reviews__rating--big'>5.0</span> out of 5
        </div>
        <div className='reviews__number'>1.2K Ratings</div>
      </div>
      <div className='reviews__items'>
        {reviews.map((review: ReviewI, index: number) => (
          <Review
            key={index}
            name={review.name}
            label={review.label}
            date={review.date}
            text={review.text}
          ></Review>
        ))}
      </div>
    </div>
  );
};
