// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUMifMLbfY1G-sgCJ1Q0QbKiwBr2dTlxo",
  authDomain: "amazeyou-731e2.firebaseapp.com",
  projectId: "amazeyou-731e2",
  storageBucket: "amazeyou-731e2.appspot.com",
  messagingSenderId: "685295042803",
  appId: "1:685295042803:web:2d81d0056fec2c964f7786",
  measurementId: "G-SPJ7K2F0NV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export {db}

