import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './MobileApp.css';
import { LandingStagesEnum } from './types';
import { Profile } from './components/profile';
import { Chart1 } from './components/chart_1';
import { Chart2 } from './components/chart_2';
import { Loader } from './components/loader';
import { Email } from './components/email';
import { Name } from './components/name';
import { Main } from './components/main';

const MobileApp: React.FC = () => {
  const [activeStage, setActiveStage] = useState<LandingStagesEnum>(
    LandingStagesEnum.PROFILE
  );
  const [bodyType, setBodyType] = useState<number>(1);
  const [fashionLevel, setFashionLevel] = useState<number>(1);
  const [fashionPersonality, setFashionPersonality] = useState<number>(1);
  const [problemArea, setProblemArea] = useState<number>(1);
  const [currentStyle, setCurrentStyle] = useState<number>(1);

  const [userName, setuserName] = useState<string>('');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const quizResult = queryParams.get('hash');

  const result = quizResult?.split('').reverse();

  useEffect(() => {
    setBodyType(result?.[0] ? +result?.[0] : 1);
    setFashionLevel(result?.[1] ? +result?.[1] : 1);
    setFashionPersonality(result?.[2] ? +result?.[2] : 1);
    setProblemArea(result?.[3] ? +result?.[3] : 1);
    setCurrentStyle(result?.[4] ? +result?.[4] : 1);
  }, [result]);

  const getActiveStageComponent = () => {
    switch (activeStage) {
      case LandingStagesEnum.PROFILE:
        return (
          <Profile
            setActiveStage={setActiveStage}
            bodyType={bodyType}
            fashionLevel={fashionLevel}
            fashionPersonality={fashionPersonality}
            problemArea={problemArea}
            currentStyle={currentStyle}
          />
        );
      case LandingStagesEnum.CHART_1:
        return (
          <Chart1 setActiveStage={setActiveStage} fashionLevel={fashionLevel} />
        );
      case LandingStagesEnum.LOADER:
        return <Loader setActiveStage={setActiveStage} />;
      case LandingStagesEnum.EMAIL:
        return <Email setActiveStage={setActiveStage} />;
      case LandingStagesEnum.NAME:
        return (
          <Name
            setActiveStage={setActiveStage}
            name={userName}
            setName={setuserName}
          />
        );
      case LandingStagesEnum.CHART_2:
        return <Chart2 setActiveStage={setActiveStage} name={userName} />;
      case LandingStagesEnum.MAIN:
        return (
          <Main
            fashionLevel={fashionLevel}
            currentStyle={currentStyle}
            styleGoal={fashionPersonality}
          />
        );
      default:
        return (
          <Profile
            setActiveStage={setActiveStage}
            bodyType={bodyType}
            fashionLevel={fashionLevel}
            fashionPersonality={fashionPersonality}
            problemArea={problemArea}
            currentStyle={currentStyle}
          />
        );
    }
  };

  return <div className='mobile-app'>{getActiveStageComponent()}</div>;
};

export default MobileApp;
