import { FullHeightLayout } from '../../layouts/full-height-layout';
import { LandingStagesEnum } from '../../types';
import { Button } from '../button';
import './index.css';
import { CustomChart1 } from './components/customChart';
import { getDates } from './utils';

interface Chart1Props {
  setActiveStage: (stage: LandingStagesEnum) => void;
  fashionLevel: number;
}

export const Chart1: React.FC<Chart1Props> = ({
  setActiveStage,
  fashionLevel,
}) => {
  const dates = getDates();

  const getLevelLabels = (level: number): string[] => {
    switch (level) {
      case 1:
        return ['Expert', 'Intermediate', 'Beginner'];
      case 2:
        return ['Expert', 'Advanced', 'Intermediate'];
      case 3:
        return ['Expert', 'Advanced', 'Advanced'];
      default:
        return ['Expert', 'Intermediate', 'Beginner'];
    }
  };

  return (
    <FullHeightLayout>
      <div className='chart1__container'>
        <div className='chart1__content'>
          <div className='chart1__title title'>
            The ultimate style guide you'll ever need to look the best
          </div>
          <div className='chart1__text'>
            We predict you will{' '}
            <span className='text-accent'>improve your style</span> by{' '}
            <span className='text-accent'>{dates.future}</span>
          </div>
          <div className='chart__container'>
            <div className='chart__y-labels'>
              {getLevelLabels(fashionLevel).map(
                (item: string, index: number) => (
                  <div className='label' key={index}>
                    {item}
                  </div>
                )
              )}
            </div>
            <div className='chart__view'>
              <CustomChart1></CustomChart1>
              <div className='chart__x-labels'>
                <div className='label'>{dates.today}</div>
                <div className='label'>{dates.future}</div>
              </div>
              <div className='chart__tooltip'>
                improve <br /> your style
              </div>
            </div>
          </div>
        </div>

        <Button
          label='continue'
          callback={() => setActiveStage(LandingStagesEnum.LOADER)}
        ></Button>
      </div>
    </FullHeightLayout>
  );
};
