import { FullHeightLayout } from '../../layouts/full-height-layout';
import { CurrentSyleImageMap, LandingStagesEnum } from '../../types';
import { Button } from '../button';

import './index.css';
import { Item, items, itemValuesMap } from './constants';
import Tooltip from '../tooltip';

interface ProfileProps {
  setActiveStage: (stage: LandingStagesEnum) => void;
  bodyType: number;
  fashionLevel: number;
  fashionPersonality: number;
  problemArea: number;
  currentStyle: number;
}

export const Profile: React.FC<ProfileProps> = ({
  setActiveStage,
  bodyType,
  fashionLevel,
  fashionPersonality,
  problemArea,
  currentStyle,
}) => {
  let itemsMap: { [key: number]: number } = {
    0: bodyType,
    1: fashionLevel,
    2: fashionPersonality,
    3: problemArea,
  };

  return (
    <FullHeightLayout>
      <div className='profile__container'>
        <div className='profile__content'>
          <div className='profile__title title'>Here’s your style profile</div>
          <div className='profile__card'>
            {items.map((item: Item) => {
              const value = itemValuesMap[item.index][itemsMap?.[item.index]];
              return (
                <div className='profile__item profile-item' key={item.index}>
                  <img
                    className='profile-item__image'
                    src={item.icon}
                    alt='item'
                  />
                  <div className='profile-item__name'>{item.label}</div>
                  <div className='profile-item__value'>
                    {value?.value}{' '}
                    {!!value?.description && (
                      <Tooltip text={value?.description}>i</Tooltip>
                    )}
                  </div>
                </div>
              );
            })}

            <img
              className='profile__image'
              src={CurrentSyleImageMap[currentStyle]}
              alt='model'
            ></img>
            <div className='profile__background'></div>
          </div>
        </div>

        <Button
          label='Continue'
          callback={() => setActiveStage(LandingStagesEnum.CHART_1)}
        ></Button>
      </div>
    </FullHeightLayout>
  );
};
