import { Button } from '../../../button';
import React, { useState, useEffect } from 'react';
import './index.css';

const HeaderWithTimer: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(10 * 60); // 10 минут в секундах

  useEffect(() => {
    if (timeLeft === 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId); // Очистка таймера при размонтировании компонента
  }, [timeLeft]);

  // Форматирование времени в MM:SS
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return {
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
    };
  };

  const { minutes, seconds } = formatTime(timeLeft);

  const scrollToOptions = () => {
    const options = document.getElementById('plan-options-first');

    options?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='header__container'>
      <div className='header__timer'>
        {minutes} : {seconds}
        <div className='header__timer-text'>
          <div>minutes</div>
          <div>seconds</div>
        </div>
      </div>
      <Button label='Get my plan' callback={() => scrollToOptions()}></Button>
    </div>
  );
};

export default HeaderWithTimer;
