import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Thanks } from './components/MobileApp/components/thanks';
import { PrivacyPolicy } from './components/MobileApp/components/privacy-policy';
import MetaPixel from './utils/MetaPixel';
import YandexCounter from './utils/YandexCounter';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/thanks',
    element: <Thanks></Thanks>,
  },
  { path: '/privacy-policy', element: <PrivacyPolicy></PrivacyPolicy> },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MetaPixel></MetaPixel>
    <YandexCounter></YandexCounter>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
