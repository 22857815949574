import startsIcon from '../../../../assets/icons/stars-icon.svg';
import './index.css';

interface ReviewProps {
  name: string;
  date: string;
  label: string;
  text: string;
}

export const Review: React.FC<ReviewProps> = ({ name, date, label, text }) => {
  return (
    <div className='review'>
      <div className='review__header'>
        <img className='review__score' src={startsIcon} alt='5 stars' />
        <div className='review__user'>{name + ', ' + date}</div>
      </div>
      <div className='review__label'>{label}</div>
      <div className='review__text'>{text}</div>
    </div>
  );
};
