import './index.css';
import twoArrowsIcon from '../../../../../../assets/icons/two-arrows-icon.svg';

import {
  CurrentSyleImageMap,
  FashionLevelNames,
  StyleGoalImageMap,
} from '../../../../types';

interface BeforeAfterProps {
  fashionLevel: number;
  currentStyle: number;
  styleGoal: number;
}

export const BeforeAfter: React.FC<BeforeAfterProps> = ({
  fashionLevel,
  currentStyle,
  styleGoal,
}) => {
  return (
    <div className='before-after__container'>
      <div className='before-after__preview preview'>
        <div className='preview__header'>
          <div className='preview__label-before'>Before</div>
          <img src={twoArrowsIcon} alt='>>' />
          <div className='preview__label-after'>After</div>
        </div>
        <div className='preview__images'>
          <img
            src={CurrentSyleImageMap[currentStyle]}
            alt='model 1'
            className='preview__image before'
          />
          <img
            src={StyleGoalImageMap[styleGoal]}
            alt='model 2'
            className='preview__image after'
          />
          <img src={twoArrowsIcon} alt='>>' className='preview__arrows' />
        </div>
        <div className='preview__info info'>
          <div className='column'>
            <div className='info__item before-after-item'>
              <div className='before-after-item__name'>Before</div>
              <div className='before-after-item__value'>
                Struggling to find the right look
              </div>
            </div>
            <div className='info__item before-after-item'>
              <div className='before-after-item__name'>Fashion Level</div>
              <div className='before-after-item__value'>
                {FashionLevelNames[fashionLevel]}
              </div>
              <div className='before-after-item__level'>
                <div
                  className={`before-after-item__level-item ${
                    fashionLevel >= 1 ? 'active' : ''
                  }`}
                ></div>
                <div
                  className={`before-after-item__level-item ${
                    fashionLevel >= 2 ? 'active' : ''
                  }`}
                ></div>
                <div
                  className={`before-after-item__level-item ${
                    fashionLevel >= 3 ? 'active' : ''
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <div className='devider'></div>
          <div className='column'>
            <div className='info__item before-after-item'>
              <div className='before-after-item__name'>After</div>
              <div className='before-after-item__value'>
                Confident in curated outfits!
              </div>
            </div>
            <div className='info__item before-after-item'>
              <div className='before-after-item__name'>Fashion Level</div>
              <div className='before-after-item__value'>Advanced</div>
              <div className='before-after-item__level'>
                <div className='before-after-item__level-item active'></div>
                <div className='before-after-item__level-item active'></div>
                <div className='before-after-item__level-item active'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
