import current1 from '../../../assets/images/current/1.png';
import current2 from '../../../assets/images/current/2.png';
import current3 from '../../../assets/images/current/3.png';
import current4 from '../../../assets/images/current/4.png';
import goal1 from '../../../assets/images/goal/1.png';
import goal2 from '../../../assets/images/goal/2.png';
import goal3 from '../../../assets/images/goal/3.png';
import goal4 from '../../../assets/images/goal/4.png';

export enum LandingStagesEnum {
  PROFILE = 'profile',
  CHART_1 = 'chart1',
  LOADER = 'loader',
  EMAIL = 'email',
  NAME = 'name',
  CHART_2 = 'chart2',
  MAIN = 'main',
}

export interface Review {
  name: string;
  date: string;
  label: string;
  text: string;
}

export const reviews: Review[] = [
  {
    name: 'OliviaS',
    date: '09/14/2024',
    label: 'Perfect!',
    text: 'I’ve always wanted to look stylish but didn’t know where to start. AmazeYou helped me put together the perfect wardrobe!',
  },
  {
    name: 'CarolineD',
    date: '10/01/2024',
    label: 'Perfect!',
    text: 'I love that AmazeYou provides style recommendations that really match my preferences and body type.',
  },
];

export const FashionLevelNames: { [key: number]: string } = {
  1: 'Beginner',
  2: 'Intermediate',
  3: 'Advanced',
};

export const CurrentSyleImageMap: { [key: number]: string } = {
  1: current1,
  2: current2,
  3: current3,
  4: current4,
};

export const StyleGoalImageMap: { [key: number]: string } = {
  1: goal1,
  2: goal2,
  3: goal3,
  4: goal4,
};
