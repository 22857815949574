import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import infoIcon from '../../../../assets/icons/info-icon.svg';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const toggleTooltip = () => {
    setIsVisible((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [isVisible]);

  return (
    <div className='tooltip__container' ref={tooltipRef}>
      <button className='tooltip__button' onClick={toggleTooltip}>
        <img className='tooltip__image' src={infoIcon} alt='info'></img>
      </button>
      {isVisible && <div className='tooltip__box'>{text}</div>}
    </div>
  );
};

export default Tooltip;
