import { FullHeightLayout } from '../../layouts/full-height-layout';
import { LandingStagesEnum } from '../../types';
import { Button } from '../button';
import { CustomChart2 } from './components/customChart';
import './index.css';

interface Chart2Props {
  setActiveStage: (stage: LandingStagesEnum) => void;
  name: string;
}

export const Chart2: React.FC<Chart2Props> = ({ setActiveStage, name }) => {
  return (
    <FullHeightLayout>
      <div className='chart2__container'>
        <div className='chart2__content'>
          <div className='chart2__title title'>
            <span className='text-accent'>{!!name && name + ', '}</span>Your
            Personal Style Guide is ready!
          </div>
          <div className='chart__container'>
            <div className='chart__y-labels'>
              <div className='label'>Fashion level</div>
            </div>
            <div className='chart__view'>
              <CustomChart2></CustomChart2>
              <div className='chart__x-labels'>
                <div className='label'>Week 1</div>
                <div className='label'>Week 2</div>
                <div className='label'>Week 3</div>
                <div className='label'>Week 4</div>
              </div>
              <div className='chart__tooltip1'>
                After <br /> 4 weeks
              </div>
              <div className='chart__tooltip2'>Now</div>
            </div>
          </div>
        </div>

        <Button
          label='continue'
          callback={() => setActiveStage(LandingStagesEnum.MAIN)}
        ></Button>
      </div>
    </FullHeightLayout>
  );
};
