import bodyStyleIcon from '../../../../assets/icons/body-style-icon.svg';
import fashionLevelIcon from '../../../../assets/icons/fashion-level-icon.svg';
import fashionPersonalityIcon from '../../../../assets/icons/fashion-personality-icon.svg';
import problemAreaIcon from '../../../../assets/icons/problem-area-icon.svg';

export interface Item {
  index: number;
  icon: string;
  label: string;
}

export interface ItemValueOptions {
  value: string;
  description?: string;
}

export const items: Item[] = [
  {
    icon: bodyStyleIcon,
    label: 'Body style',
    index: 0,
  },
  {
    index: 1,
    icon: fashionLevelIcon,
    label: 'Fashion level',
  },
  {
    index: 2,
    icon: fashionPersonalityIcon,
    label: 'Fashion personality',
  },
  {
    index: 3,
    icon: problemAreaIcon,
    label: 'Problem area',
  },
];

export const itemValuesMap: {
  [key: number]: { [key: number]: ItemValueOptions };
} = {
  0: {
    1: { value: 'Triangle', description: 'Wide hips and narrow shoulders' },
    2: {
      value: 'Inverted Triangle',
      description: 'Broad shoulders and narrow hips',
    },
    3: {
      value: 'Hourglass',
      description: 'Balanced bust and hips with a narrower waist',
    },
    4: {
      value: 'Rectangle',
      description: 'Bust, waist, and hips are roughly aligned',
    },
  },
  1: {
    1: { value: 'Beginner' },
    2: { value: 'Intermediate' },
    3: { value: 'Advanced' },
  },
  2: {
    1: {
      value: 'Natural',
      description:
        'You value comfort and simplicity in your clothing, favoring casual and minimalistic looks that make you feel at ease.',
    },
    2: {
      value: 'Classic',
      description:
        'You prefer elegant and timeless outfits, sticking to well-turned-out looks with enduring colors and styles.',
    },
    3: {
      value: 'Romantic',
      description:
        'You love feminine details and vibrant colors, taking time to enhance your appearance with makeup and accessories.',
    },
    4: {
      value: 'Dramatic',
      description:
        'You’re drawn to bold and statement-making pieces, enjoying bright colors and trendy, eye-catching designs.',
    },
  },
  3: {
    1: { value: 'Item composition' },
    2: { value: 'Time sensitivity' },
    3: { value: 'Absence of personal style' },
    4: { value: 'Fashion knowledge' },
    5: { value: 'Inspiration and ideas' },
  },
};
