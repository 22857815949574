import './index.css';
import highlightIcon1 from '../../../../../../assets/icons/highlight1.svg';
import highlightIcon2 from '../../../../../../assets/icons/highlight2.svg';
import highlightIcon3 from '../../../../../../assets/icons/highlight3.svg';
import highlightIcon4 from '../../../../../../assets/icons/highlight4.svg';
import highlightIcon5 from '../../../../../../assets/icons/highlight5.svg';
import highlightIcon6 from '../../../../../../assets/icons/highlight6.svg';
import highlightIcon7 from '../../../../../../assets/icons/highlight7.svg';

interface Item {
  icon: string;
  name: string;
  value: string;
}

export const Highlights: React.FC = () => {
  const items: Item[] = [
    {
      icon: highlightIcon1,
      name: 'Personalized recommendations',
      value:
        'each outfit is curated based on your preferences, personality and body type',
    },
    {
      icon: highlightIcon2,
      name: 'Easy-to-apply outfits',
      value:
        'ready-to-shop looks from the most popular fashion brands in your proximity',
    },
    {
      icon: highlightIcon3,
      name: 'Time-saving',
      value:
        'no more spending hours searching for the right clothes, everything is prepared for you',
    },
    {
      icon: highlightIcon4,
      name: 'Convenience and speed',
      value: 'get personalized styling recommendations in just minutes',
    },
    {
      icon: highlightIcon5,
      name: 'Expert support',
      value: 'professional advice and style recommendations',
    },
    {
      icon: highlightIcon6,
      name: 'Guaranteed results',
      value: "you're sure to find something that fits your style",
    },
    {
      icon: highlightIcon7,
      name: 'Regular updates',
      value:
        'new looks based on current fashion trends, seasonality and latest collections',
    },
  ];

  return (
    <div className='highlights__container'>
      <div className='highlights__title title'>
        Highlights of Your Style Guide
      </div>

      <div className='highlights__items'>
        {items.map((item: Item, index: number) => (
          <div className='highlights__item highlights-item' key={index}>
            <img className='highlights-item__icon' src={item.icon} alt='icon' />
            <div className='highlights-item__info'>
              <div className='highlights-item__name'>{item.name}</div>
              <div className='highlights-item__value'>{item.value}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
