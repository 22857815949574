import { Input } from 'antd';
import { FullHeightLayout } from '../../layouts/full-height-layout';
import { LandingStagesEnum } from '../../types';
import { Button } from '../button';
import './index.css';

interface NameProps {
  setName: (value: string) => void;
  name: string;
  setActiveStage: (stage: LandingStagesEnum) => void;
}

export const Name: React.FC<NameProps> = ({
  setActiveStage,
  setName,
  name,
}) => {
  return (
    <FullHeightLayout>
      <div className='name__container'>
        <div className='name__content'>
          <div className='name__title title'>What’s your name?</div>
          <Input
            className='name__input'
            placeholder='Your name'
            size='large'
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <Button
          label='continue'
          disabled={!name}
          callback={() => setActiveStage(LandingStagesEnum.CHART_2)}
        ></Button>
      </div>
    </FullHeightLayout>
  );
};
