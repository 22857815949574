import { FullHeightLayout } from '../../layouts/full-height-layout';
import thanksImage from '../../../../assets/images/thanks.jpg';
import './index.css';

export const Thanks: React.FC = () => {
  return (
    <FullHeightLayout>
      <div className='thanks__container'>
        <img src={thanksImage} alt='thanks' className='thanks__image'></img>
        <div className='thanks__title title'>
          Thank you for your interest in Amaze
          <span className='text-accent'>You</span>!
        </div>
        <p>
          We are currently in the development phase of the AmazeYou app. Please{' '}
          <span className='text-accent'>look out for an email </span>
          regarding the release date and further information.
        </p>

        <p>
          We are glad to have you on board and are looking forward to providing
          you with the best personal style app on the planet!
        </p>

        <p>AmazeYou team!</p>
      </div>
    </FullHeightLayout>
  );
};
