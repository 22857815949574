import { LineChart } from '@mui/x-charts';

export const CustomChart2: React.FC = () => {
  return (
    <LineChart
      height={210}
      grid={{ horizontal: true, vertical: false }}
      series={[
        {
          type: 'line',
          curve: 'catmullRom',
          data: [null, 100, 125, 150, 175, 225, 550, 650, 750, 1000],
          valueFormatter: (value) => (value == null ? 'NaN' : value.toString()),
          showMark: ({ index }) => index === 1 || index === 9,
          color: '#ff8c80',
          area: true,
        },
      ]}
      leftAxis={null}
      bottomAxis={null}
      margin={{ bottom: 14, left: 24, right: 24, top: 12 }}
      sx={{
        '& .MuiLineElement-root': {
          strokeWidth: 8,
        },
        '& .MuiMarkElement-root': {
          strokeWidth: 0.5,
          scale: 2.5,
        },
        '& .MuiAreaElement-root': {
          fill: "url('#myGradient')",
        },
      }}
    >
      <defs>
        <linearGradient id='myGradient' gradientTransform='rotate(90)'>
          <stop offset='0%' stopColor='#ff8c80' />
          <stop offset='100%' stopColor='rgba(255, 255, 255, 0)' />
        </linearGradient>
      </defs>
    </LineChart>
  );
};
